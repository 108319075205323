<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="名称">
          <el-input v-model="searchData.name" placeholder="请输入" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchData.status" placeholder="请选择" size="small">
            <el-option label="启用" :value="20"> </el-option>
            <el-option label="禁用" :value="30"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-btn">
      <el-button v-checkbtn="isShowBtn(AUTH_BTN.station_list_add)" @click="$router.push({ name: 'ParkStationAdd' })" type="primary" icon="el-icon-plus" size="small"
        >新增</el-button
      >
    </div>
    <tp-table :isNeedSerialNumber="false" :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
    <!-- 占位配置 -->
    <el-dialog title="占位配置" :visible.sync="dialogVisible" width="900px">
      <div class="zhanwei-box">
        <!--通用 -->
        <admin-title title="通用设置"></admin-title>
        <el-form>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label-width="100px" label="每小时收费" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                <el-input-number
                  v-model="zhanweiData.fee_hour"
                  :step="0.01"
                  :precision="2"
                  step-strictly
                  size="mini"
                  :min="0"
                  style="width: 150px; margin-right: 10px"
                  clearable
                  placeholder="请输入"
                ></el-input-number
                >元
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="每日收费上限" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                <el-input-number
                  v-model="zhanweiData.day_max_seat_money"
                  :step="0.01"
                  :precision="2"
                  step-strictly
                  size="mini"
                  :min="0"
                  style="width: 150px; margin-right: 10px"
                  clearable
                  placeholder="请输入"
                ></el-input-number
                >元
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label-width="100px" label="充电前" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                <el-input-number
                  v-model="zhanweiData.day_charge_before_min_free"
                  :step="1"
                  step-strictly
                  size="mini"
                  :min="0"
                  style="width: 150px; margin-right: 10px"
                  clearable
                  placeholder="请输入"
                ></el-input-number
                >分钟可免费停车
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 白天 -->
        <admin-title title="白天设置"></admin-title>
        <el-form>
          <el-row :gutter="10">
            <el-col :span="20">
              <el-form-item label-width="100px" label="白天时间段" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                <div class="row">
                  <el-time-select
                    @change="changeDayStartTime"
                    v-model="day_start_time"
                    editable
                    size="mini"
                    style="width: 120px; margin-right: 10px"
                    placeholder="开始时间"
                    :picker-options="{
                      start: '00:00',
                      step: '01:00',
                      end: '24:00',
                      format: 'HH:mm:ss'
                    }"
                  >
                  </el-time-select>
                  -
                  <el-time-select
                    @change="changeDayEndTime"
                    v-model="day_end_time"
                    editable
                    size="mini"
                    style="width: 120px; margin-left: 10px"
                    placeholder="结束时间"
                    :picker-options="{
                      start: '00:00',
                      step: '01:00',
                      end: '24:00',
                      format: 'HH:mm:ss'
                    }"
                  >
                  </el-time-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label-width="90px" label="充电满" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                <el-input-number
                  v-model="zhanweiData.day_charge_hour_free"
                  :step="0.01"
                  :precision="2"
                  step-strictly
                  size="mini"
                  :min="0"
                  style="width: 150px; margin: 0 10px"
                  clearable
                  placeholder="请输入"
                ></el-input-number
                >小时，或消费满
                <el-input-number
                  v-model="zhanweiData.day_charge_money_free"
                  :step="0.01"
                  :precision="2"
                  step-strictly
                  size="mini"
                  :min="0"
                  style="width: 150px; margin: 0 10px"
                  clearable
                  placeholder="请输入"
                ></el-input-number
                >元可免费停车

                <el-input-number
                  v-model="zhanweiData.day_charge_after_hour_free"
                  :step="1"
                  step-strictly
                  size="mini"
                  :min="0"
                  style="width: 150px; margin: 0 10px"
                  clearable
                  placeholder="请输入"
                ></el-input-number
                >分钟
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 晚上 -->
        <admin-title title="晚上设置"></admin-title>
        <el-form>
          <el-row :gutter="10">
            <el-col :span="20">
              <el-form-item label-width="100px" label="晚上时间段" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                <div class="row">
                  <el-time-select
                    @change="changeNightStartTime"
                    v-model="night_start_time"
                    editable
                    size="mini"
                    style="width: 120px; margin-right: 10px"
                    placeholder="开始时间"
                    :picker-options="{
                      start: '00:00',
                      step: '01:00',
                      end: '24:00',
                      format: 'HH:mm:ss'
                    }"
                  >
                  </el-time-select>
                  -
                  <el-time-select
                    @change="changeNightEndTime"
                    v-model="night_end_time"
                    editable
                    size="mini"
                    style="width: 120px; margin-left: 10px"
                    placeholder="结束时间"
                    :picker-options="{
                      start: '00:00',
                      step: '01:00',
                      end: '24:00',
                      format: 'HH:mm:ss'
                    }"
                  >
                  </el-time-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label-width="90px" label="充电满" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                <el-input-number
                  v-model="zhanweiData.night_charge_hour_free"
                  :step="0.01"
                  :precision="2"
                  step-strictly
                  size="mini"
                  :min="0"
                  style="width: 150px; margin: 0 10px"
                  clearable
                  placeholder="请输入"
                ></el-input-number
                >小时，或消费满
                <el-input-number
                  v-model="zhanweiData.night_charge_money_free"
                  :step="0.01"
                  :precision="2"
                  step-strictly
                  size="mini"
                  :min="0"
                  style="width: 150px; margin: 0 10px"
                  clearable
                  placeholder="请输入"
                ></el-input-number
                >元可免费停车
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveZhanwei" size="small">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 充电配置  -->
    <el-dialog title="充电配置" :visible.sync="showFeeRate" width="1000px">
      <el-form ref="form" inline :model="feeRateData" label-width="100px">
        <el-form-item label="尖电费费率">
          <el-input-number
            :step="0.00001"
            :precision="5"
            step-strictly
            size="small"
            :min="0"
            style="width: 300px"
            clearable
            v-model="feeRateData.charge_fee_rate.sharp_kwh_price"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="尖服务费费率">
          <el-input-number
            :step="0.00001"
            :precision="5"
            step-strictly
            size="small"
            :min="0"
            style="width: 300px"
            clearable
            v-model="feeRateData.charge_fee_rate.sharp_service_price"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="峰电费费率">
          <el-input-number
            :step="0.00001"
            :precision="5"
            step-strictly
            :min="0"
            style="width: 300px"
            clearable
            v-model="feeRateData.charge_fee_rate.peak_kwh_price"
            size="small"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="峰服务费费率">
          <el-input-number
            :step="0.00001"
            :precision="5"
            step-strictly
            :min="0"
            style="width: 300px"
            clearable
            v-model="feeRateData.charge_fee_rate.peak_service_price"
            size="small"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="平电费费率">
          <el-input-number
            :step="0.00001"
            :precision="5"
            step-strictly
            :min="0"
            style="width: 300px"
            clearable
            v-model="feeRateData.charge_fee_rate.flat_kwh_price"
            size="small"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="平服务费费率">
          <el-input-number
            :step="0.00001"
            :precision="5"
            step-strictly
            :min="0"
            style="width: 300px"
            clearable
            v-model="feeRateData.charge_fee_rate.flat_service_price"
            size="small"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="谷服务费率">
          <el-input-number
            :step="0.00001"
            :precision="5"
            step-strictly
            :min="0"
            style="width: 300px"
            clearable
            v-model="feeRateData.charge_fee_rate.hollow_kwh_price"
            size="small"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="谷服务费费率">
          <el-input-number
            :step="0.00001"
            :precision="5"
            step-strictly
            :min="0"
            style="width: 300px"
            clearable
            v-model="feeRateData.charge_fee_rate.hollow_service_price"
            size="small"
            placeholder="请输入"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div class="title">时间段配置</div>
      <!-- 时间段 -->
      <div class="fee-add-btn">
        <el-button icon="el-icon-plus" type="primary" size="mini" plain @click="addFee">添加策略</el-button>
      </div>
      <div class="content">
        <div class="row">
          <div class="col">电费策略</div>
          <div class="col">开始时间</div>
          <div class="col">结束时间</div>
          <div class="col">操作</div>
        </div>
        <!-- 策略 -->
        <div class="row" v-for="(item, i) in feeRateData.charge_fee" :key="i">
          <div class="col">
            <el-select style="width: 130px" size="small" v-model="item.charge_fee_rate_type" placeholder="请选择">
              <el-option v-for="item in feeRate" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <!-- 开始时间 -->
          <div class="col">
            <el-time-select
              v-model="item.start_hour_format"
              editable
              size="small"
              style="width: 120px"
              placeholder="开始时间"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '24:00',
                format: 'HH:mm:ss'
              }"
            >
            </el-time-select>
          </div>
          <!-- 结束时间 -->
          <div class="col">
            <el-time-select
              v-model="item.end_hour_format"
              editable
              size="small"
              style="width: 120px"
              placeholder="结束时间"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '24:00',
                format: 'HH:mm:ss'
              }"
            >
            </el-time-select>
          </div>
          <div class="col last">
            <!-- <el-button type="primary" size="mini" @click="addTime(i)">添加</el-button> -->
            <el-button plain type="danger" size="mini" @click="removeTime(i)">删除</el-button>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="showFeeRate = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveFeeRate" size="small">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { changeStatusAPI, getStationListAPI, delStationAPI, getFeeListAPI, addFeeAPI, getFeeRateAPI, getFeeRateDetailAPI, updateFeeRateAPI } from './api'
const columns = [
  {
    label: 'ID',
    prop: 'id',
    minWidth: '60',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '名称',
    prop: 'name',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['name']}</p>
    }
  },
  {
    label: '封面图',
    prop: 'orderNo',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-image
            style="width: 80px; height: 80px;display:block;margin:5px 0;border-radius:5px"
            src={row['background_images']?.[0]?.url}
            preview-src-list={row['background_images']?.map((item) => {
              return item.url
            })}
          ></el-image>
        </div>
      )
    }
  },

  {
    label: '位置',
    prop: 'costPrice',
    minWidth: '180',
    customRender(h, row) {
      return <p>{this.handleAddress(row)}</p>
    }
  },
  {
    label: '状态',
    prop: 'parking_spaces_count',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          <el-tag type={row.status == 20 ? 'primary' : 'danger'} size="small">
            {' '}
            {row.status == 20 ? '启用' : '禁用'}
          </el-tag>
        </div>
      )
    }
  },
  {
    label: '停车位数量',
    prop: 'parking_spaces_count',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['parking_spaces_count']}</p>
    }
  },
  {
    label: '充电桩数量',
    prop: 'charging_pile_count',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['charging_pile_count']}</p>
    }
  },
  {
    label: '充电枪数量',
    prop: 'charging_pile_gun_count',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['charging_pile_gun_count']}</p>
    }
  },

  {
    label: '是否支持预约',
    prop: 'is_reservable',
    minWidth: '120',
    customRender(h, row) {
      return <div>{row['is_reservable'] ? '支持' : '不支持'}</div>
    }
  },

  {
    label: '创建时间',
    prop: 'created_at',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['created_at']}</p>
    }
  },

  {
    label: '操作',
    minWidth: '160',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            style="margin-right: 10px"
            disabled={!this.isShowBtn(this.AUTH_BTN.station_list_status)}
            underline={false}
            type={row.status == 30 ? 'primary' : 'danger'}
            onClick={() => this.handleStatus(row)}
          >
            {row.status == 30 ? '启用' : '禁用'}
          </el-link>
          <el-link
            style="margin-right: 10px"
            disabled={!this.isShowBtn(this.AUTH_BTN.station_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'ParkStationEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.station_list_del)}
            style="margin-right: 10px"
            underline={false}
            type="primary"
            onClick={() => this.handleRemove(row)}
          >
            删除
          </el-link>

          <el-link
            style="margin-right: 10px"
            disabled={!this.isShowBtn(this.AUTH_BTN.station_list_fee_config)}
            underline={false}
            type="primary"
            onClick={() => this.handleFee(row)}
          >
            充电配置
          </el-link>
          <el-link disabled={!this.isShowBtn(this.AUTH_BTN.station_list_dian_config)} underline={false} type="primary" onClick={() => this.handleZhanwei(row)}>
            占位配置
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      showFeeRate: false,
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        name: '',
        status: ''
      },
      charging_fees: [],
      station_id: '',
      feeRate: [], //费率列表
      feeRateData: {
        //充电配置
        charge_fee_rate: {
          station_id: '',
          sharp_kwh_price: '',
          sharp_service_price: '',
          peak_kwh_price: '',
          peak_service_price: '',
          flat_kwh_price: '',
          flat_service_price: '',
          hollow_kwh_price: '',
          hollow_service_price: ''
        },
        charge_fee: []
      },
      zhanweiData: {
        station_id: '',
        fee_hour: '',
        day_max_seat_money: '',
        night_charge_hour_free: '',
        night_charge_money_free: '',
        night_range_time: [],
        day_charge_before_min_free: '',
        day_charge_after_hour_free: '',
        day_charge_hour_free: '',
        day_charge_money_free: '',
        day_range_time: []
      },
      night_start_time: '',
      night_end_time: '',
      day_start_time: '',
      day_end_time: ''
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    // this.getList()
  },
  activated() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  methods: {
    async getList() {
      const { name, status } = this.searchData
      let params = { page: this.currentPage, page_size: this.pageSize, name, status }
      const res = await getStationListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
      // this.list.forEach((item) => {
      //   item.background_images = JSON.parse(item.background_images)
      // })
    },
    // 删除
    handleRemove({ id, name }) {
      this.$confirm(`此操作将删除充电站【${name}】, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delStationAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 计算地址
    handleAddress({ province, city, district, address }) {
      return province + city + district + address || ''
    },
    // 充电配置按钮
    async handleFee({ id }) {
      this.station_id = id
      this.feeRateData = await getFeeListAPI(id) //电费列表
      this.feeRate = await getFeeRateAPI(id) //费率列表
      this.showFeeRate = true
    },
    //  添加一条时间段配置
    addFee() {
      const obj = {
        start_hour_format: '',
        end_hour_format: '',
        charge_fee_rate_type: undefined
      }
      this.feeRateData.charge_fee.push(obj)
    },
    //  删除一条电费时间段配置
    removeTime(i) {
      this.feeRateData.charge_fee.splice(i, 1)
    },
    // 保存充电电费配置
    saveFeeRate() {
      const data = {
        station_id: this.station_id,
        ...this.feeRateData
      }
      addFeeAPI(data).then(() => {
        this.$message.success('电费配置成功')
        this.showFeeRate = false
      })
    },
    // 占位配置按钮
    async handleZhanwei({ id }) {
      this.zhanweiData.station_id = id
      const res = await getFeeRateDetailAPI(id)
      this.dialogVisible = true
      if (res) {
        this.zhanweiData = res
        this.night_end_time = res.night_range_time[1]
        this.night_start_time = res.night_range_time[0]
        this.day_start_time = res.day_range_time[0]
        this.day_end_time = res.day_range_time[1]
      } else {
        this.zhanweiData = {
          station_id: id,
          fee_hour: '',
          day_max_seat_money: '',
          night_charge_hour_free: '',
          night_charge_money_free: '',
          night_range_time: [],
          day_charge_before_min_free: '',
          day_charge_after_hour_free: '',
          day_charge_hour_free: '',
          day_charge_money_free: '',
          day_range_time: []
        }
        this.night_end_time = ''
        this.night_start_time = ''
        this.day_start_time = ''
        this.day_end_time = ''
      }
    },
    // 保存占位配置
    async saveZhanwei() {
      this.zhanweiData.day_range_time = [this.day_start_time, this.day_end_time]
      this.zhanweiData.night_range_time = [this.night_start_time, this.night_end_time]
      await updateFeeRateAPI(this.zhanweiData)
      this.$message.success('配置成功')
      this.dialogVisible = false
    },
    changeDayStartTime() {
      if (this.day_start_time && this.day_end_time) {
        this.night_start_time = this.day_end_time
        this.night_end_time = this.day_start_time
      }
    },
    changeDayEndTime() {
      if (this.day_start_time && this.day_end_time) {
        this.night_start_time = this.day_end_time
        this.night_end_time = this.day_start_time
      }
    },
    changeNightStartTime() {
      if (this.night_start_time && this.night_end_time) {
        this.day_start_time = this.night_end_time
        this.day_end_time = this.night_start_time
      }
    },
    changeNightEndTime() {
      if (this.night_start_time && this.night_end_time) {
        this.day_start_time = this.night_end_time
        this.day_end_time = this.night_start_time
      }
    },
    handleStatus({ id, status }) {
      const str = status == 30 ? '启用' : '禁用'
      const data = { id, status: status == 20 ? 30 : 20 }
      this.$confirm(`${str}该充电站, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          changeStatusAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      // .el-select .el-input {
      //   width: 100px;
      // }
      // .input-with-select {
      //   vertical-align: middle;
      // }
      // .input-with-select .el-input-group__prepend {
      //   background-color: #fff;
      // }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
    .tp-table_row {
      height: 100%;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
  .el-dialog {
    .title {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .fee-add-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .content {
      padding: 0 10px;
      width: 100%;
      background-color: #fafafa;

      .row {
        padding: 10px 0;
        display: flex;
        align-items: center;
        .col {
          flex: 3;
          margin-right: 10px;
          &:last-child {
            flex: 1;
            margin-right: 0;
          }
        }
        .last {
          display: flex;
          align-items: center;
        }
      }
    }
    .zhanwei-box {
      .row {
        display: flex;
        align-items: center;
      }
      .el-form-item {
        margin-bottom: 5px;
        .el-form-item__label {
          text-align: left;
        }
      }
    }
  }
}
</style>
