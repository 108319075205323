export default {
  /**
   * 用户管理
   */
  // 用户列表
  user_list_car: 'UserCarList',
  user_list_balance: 'UserBalanceList',
  /**
   * 订单管理
   */
  // 订单列表
  order_pay_detail: 'PayDetail',
  // 预约订单
  order_appoint_detail: 'OrderAppointDetail',
  /**
   * 充值管理
   */
  //   充值流水
  recharge_order_detail: 'RechargeOrderDetail',
  recharge_order_del: 'recharge_order_del',
  // 充值档位
  recharge_value_add: 'ChargeValueAdd',
  recharge_value_edit: 'ChargeValueEdit',
  recharge_value_del: 'recharge_value_del',
  // 退款申请
  recharge_value_review: 'recharge_value_review',
  recharge_value_tuikuan: 'recharge_value_tuikuan',
  recharge_value_tuikuan_detail: 'recharge_value_tuikuan_detail',
  /**
   * 车辆管理
   */
  // 品牌管理
  brand_list_add: 'CarBrandAdd',
  brand_list_edit: 'CarBrandEdit',
  brand_list_del: 'brand_list_del',
  // 车型管理
  model_list_add: 'CarModelAdd',
  model_list_edit: 'CarModelEdit',
  model_list_del: 'model_list_del',

  /**
   * 停车场管理
   */
  // 充电站管理
  station_list_add: 'ParkStationAdd',
  station_list_edit: 'ParkStationEdit',
  station_list_del: 'station_list_del',
  station_list_fee_config: 'station_list_fee_config',
  station_list_dian_config: 'station_list_dian_config',
  station_list_status: 'station_list_status',
  // 地锁管理
  lock_list_add: 'ChargeLockAdd',
  lock_list_edit: 'ChargeLockEdit',
  lock_list_config: 'lock_list_config',
  // 停车位管理
  space_list_add: 'ParkSpaceAdd',
  space_list_edit: 'ParkSpaceEdit',
  space_list_del: 'space_list_del',
  // 充电桩管理
  pile_list_add: 'ChargePileAdd',
  pile_list_edit: 'ChargePileEdit',
  pile_list_config: 'pile_list_config',
  /**
   * 运营配置
   */
  // 优惠券管理
  coupon_list_add: 'CouponAdd',
  coupon_list_edit: 'CouponEdit',
  coupon_list_del: 'coupon_list_del',
  // 帮助中心
  help_list_add: 'HelpCenterAdd',
  help_list_edit: 'HelpCenterEdit',
  help_list_del: 'help_list_del',
  // 协议设置
  agree_list_add: 'agree_list_add',
  // banner设置
  banner_list_add: 'banner_list_add',
  /**
   * 权限管理
   */
  // 管理员
  user_list_add: 'UserAdd',
  user_list_edit: 'UserEdit',
  user_list_del: 'user_list_del',
  user_list_status: 'user_list_status',
  //   角色
  role_list_add: 'RoleAdd',
  role_list_edit: 'RoleEdit',
  role_list_del: 'role_list_del',
  role_list_menu: 'role_list_menu',
  //   菜单
  menu_list_add: 'MenuAdd',
  menu_list_edit: 'MenuEdit',
  menu_list_del: 'menu_list_del',
  //   省市区管理
  area_list_add: 'AreaAdd',
  area_list_edit: 'AreaEdit',
  area_list_del: 'area_list_del',
  /***
   * 系统设置
   */
  // 字典管理
  dict_list_add: 'dictManageAdd',
  dict_list_edit: 'dictManageEdit',
  dict_list_del: 'dict_list_del',
  dict_list_add_value: 'DictValueManage',
  dict_value_list_add: 'dict_value_list_add',
  dict_value_list_edit: 'dict_value_list_edit',
  dict_value_list_del: 'dict_value_list_del',
  // 开发设置
  dev_list_storage_add: 'dev_list_storage_add'
}
