import { http } from '@/http/axios.js'
// 列表
export function getStationListAPI(params) {
  return http({
    url: '/company/chargingStation/list',
    method: 'get',
    params
  })
}
// 新增
export function addStationAPI(data) {
  return http({
    url: '/company/chargingStation/add',
    method: 'post',
    data
  })
}
// 编辑
export function editStationAPI(data) {
  return http({
    url: '/company/chargingStation/edit',
    method: 'post',
    data
  })
}
// 删除
export function delStationAPI(id) {
  return http({
    url: '/company/chargingStation/delete',
    method: 'post',
    data: { id }
  })
}
// 详情
export function getStationDetailAPI(id) {
  return http({
    url: '/company/chargingStation/detail',
    method: 'get',
    params: { id }
  })
}
// 充电配置详情
export function getFeeListAPI(station_id) {
  return http({
    url: '/company/chargingStation/getChargeFeeSetting',
    method: 'get',
    params: { station_id }
  })
}
// 新增\编辑电费
export function addFeeAPI(data) {
  return http({
    url: '/company/chargingStation/settingChargeFee',
    method: 'post',
    data
  })
}
// 删除电费
export function delAPI(station_id) {
  return http({
    url: '/company/chargingStation/delFee',
    method: 'post',
    data: { station_id }
  })
}
// 费率列表
export function getFeeRateAPI(station_id) {
  return http({
    url: '/company/charging/cmsChargingFeeRate/feeCalMethods',
    method: 'get',
    params: { station_id }
  })
}
// 占位费详情
export function getFeeRateDetailAPI(station_id) {
  return http({
    url: '/company/chargingStation/getSeatFeeSetting',
    method: 'get',
    params: { station_id }
  })
}
// 更新占位费率
export function updateFeeRateAPI(data) {
  return http({
    url: '/company/chargingStation/settingSeatFee',
    method: 'post',
    data
  })
}
// status
export function changeStatusAPI(data) {
  return http({
    url: '/company/chargingStation/updateStatus',
    method: 'post',
    data
  })
}
